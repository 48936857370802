/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.chart-container{
  position: relative;
  margin: auto;
  height: 60vh;
}
.chart-data-selector{
  margin-top: 10px;
}
input[name="accu-selector"] {
  margin-left: 5px;
}
.plus{
  color: green;
}
.minus{
  color: red;
}
.result span{
  margin-right: 3px;;
}
@media screen and (min-width: 982px) {
  .chart-container{
    height: 65vh;
  }
  .chart-data-selector{
    margin-top: auto;
  }
  .date-picker{
    float: right;
  }
}

@media screen and (min-width: 1183px) {
  .table-totals{
    padding: 5px;
    width: 33%;
    float: left;
  }
  .table-records{
    padding: 5px;
    width: 50%;
    float: left;
  }
  .table-totals table td,.table-records table td{
    padding:0.4rem
  }
}